.wrapper {
  &:not(.withHeaderImage) {
    @apply py-24;
  }
}

.pageHeader {
  @apply pb-12;
  
  .pageHeaderContent {
    @apply max-w-4xl mx-auto px-4 lg:px-0;
    
    .pageTitle {
      @apply text-5xl text-gray-800 mb-4;
    }
  }
}


.headerImageWrapper {
  width: 100%;
  
  .headerImage {
    width: 100%;
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 100%,
      0% 70%
    );
    @apply h-80 object-cover;
  }
}

.storyDate {
  @apply text-gray-500;
}