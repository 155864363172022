.headerImage {
  @apply h-80 w-full object-cover mb-12;
}

.pageTitle {
  @apply max-w-4xl mx-auto text-5xl mb-8;
}

.content {  
  @apply px-4 lg:px-0;
}

.section {
  & + & {
    @apply mt-8;
  }
}