.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  
  aside {
    .pluginWrapper {
      width: 100%;
      height: 100%;
      // margin: 1rem;
      
      iframe {
        width: 100%;
        height: 100%;
        // @apply border-b border-solid border-gray-200;
      }
    }
  }
}