.base {
  display: flex;
  align-items: center;
  appearance: none;
  height: fit-content;
  width: fit-content;
  @apply gap-2 text-primary-500;
}

.outline {
  border-radius: calc(infinity * 1px);
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 0px #fbece900;
  @apply py-1 px-4 border-2 border-solid border-primary-100 shadow-transparent;
  
  &:hover {
    box-shadow: 0px 0px 0px 3px #fbece9;
    @apply shadow-primary-200;
  }
}

.underline {
  @apply py-1 border-b-2 border-solid border-primary-100;
}