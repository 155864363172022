.item {
  @apply flex items-center py-5 px-5;
  
  &, * {
    transition: 
      opacity 0.3s ease-in-out 0.1s,
      gap 0.3s ease-in-out,
      max-width 0.3s cubic-bezier(.18,.89,.32,1),
      min-width 0.3s cubic-bezier(.18,.89,.32,1),
    ;
  }
  
  .labelWrapper {
    overflow: hidden;
    // min-width: 100%;
    flex: 1;
    
    @media screen and (min-width: 1024px) {
      min-width: 250px;
    }
  }
  
  .label {
    white-space: nowrap;
    overflow: hidden;
    @apply font-medium text-lg text-gray-800 pl-4;
  }
  
  &:not(:last-child) {
    @apply border-b border-solid border-gray-200;
  }
  
  &:not(.active) {
    @apply gap-0;
    
    &, * {
      transition: 
        opacity 0.3s ease-in-out 0.0s,
        gap 0.3s ease-in-out .1s,
        max-width 0.2s ease-in-out .1s,
        min-width 0.2s ease-in-out .1s,
      ;
    }
    
    .labelWrapper {
      opacity: 0;
      min-width: 0;
      max-width: 0;
    }
    
    .arrow {
      opacity: 0;
      max-width: 0;
    }
  }
}