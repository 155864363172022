.container {
  position: relative;
  height: 5px;
  width: 100%;
  overflow: hidden;
  border-radius: 9999px;
  @apply bg-gray-100;
  
  .indicator {
    animation: animation-ozg7p2 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  
  .twin {
    animation: animation-19gglwu 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
  }
  
  .indicator, .twin {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: auto;
    transition: transform 0.2s linear;
    transform-origin: left;
    animation-play-state: paused;
    visibility: hidden;
    border-radius: 9999px;
    @apply bg-gray-300;
  }
  
  &.busy {
    .indicator, .twin {
      animation-play-state: running;
      visibility: visible;
    }
  }
}

@keyframes animation-19gglwu {
  0% {
      left: -200%;
      right: 100%;
  }

  60% {
      left: 107%;
      right: -8%;
  }

  100% {
      left: 107%;
      right: -8%;
  }
}

@keyframes animation-ozg7p2 {
  0% {
      left: -35%;
      right: 100%;
  }

  60% {
      left: 100%;
      right: -90%;
  }

  100% {
      left: 100%;
      right: -90%;
  }
}