.table {
  @apply table-auto w-full;
}

.title {
  @apply mb-2;
}

.cell {
  text-align: left;
}

th.cell {
  @apply py-2 text-lg font-medium text-gray-700;
  @apply border-b border-solid border-gray-300;
}

tbody {
  .cell {
    @apply py-2;
  }
  
  .row {
    position: relative;
    background-color: transparent;
    
    --row-overflow-fx: 8px;
    
    &, * {
      &, &::after {
        transition: all 0.3s ease-in-out;
        transform-origin: center;
      }
    }
    
    &::after {
      content: '';
      position: absolute;
      inset: 0px;
      top: calc(1px/2);
      height: 80%;
      width: calc(100% + 0px);
      transform: translateX(0px);
      z-index: -1;
      
      @apply bg-transparent rounded-md;
    }

    &:hover::after {
      width: calc(100% + (var(--row-overflow-fx) * 2));
      height: 100%;
      transform: translateX(calc(var(--row-overflow-fx) * -1));
      @apply bg-gray-100;
    }
  }
}

.row {
  @apply border-b border-solid border-gray-100;
}