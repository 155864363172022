.container {
  display: block;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 4px #00000000;
  border-radius: 8px;
  
  &:hover {
    box-shadow: 0px 0px 0px 12px #0000000f;
    background: #0000000f;
  }
}

.featureImage {
  width: 100%;
  object-fit: cover;
  @apply mb-3 h-48 rounded-lg bg-gray-100;
}

.title {
  @apply text-lg leading-5;
}

.subtitle {
  @apply text-gray-500 leading-5 mt-1;
}

.link {
  display: flex;
  align-items: center;
  @apply text-primary-500 mt-2 gap-1;
  
  .linkIcon {
    translate: 0 2px;
  }
}
