.content {
  a {
    @apply text-blue-500 underline;
  }
  
  strong, b {
    @apply font-semibold;
  }
  
  p, td, ul, li {
    @apply text-gray-600 font-normal;
  }
  
  p {
    @apply mb-2;
  }
  
  p + h4, ul + h4  {
    @apply mt-6;
  }
  
  p + h3, ul + h3 {
    @apply mt-8;
  }
  
  h3, h4 {
    @apply text-gray-700;
  }
  
  h3 {
    @apply text-3xl;
    
    & + * {
      @apply mt-3;
    }
  }
  
  h4 {
    @apply text-2xl;
    
    & + * {
      @apply mt-1;
    }
  }
  
  ul {
    @apply list-disc pl-6;
    
    & + * {
      @apply mt-4;
    }
  }
  
  li p {
    @apply mb-0;
  }
  
  li:has(ul) {
    @apply mb-2;
  }
}