.wrapper {
  position: relative;
  transition: .2s ease-in-out .1s;
  
  @media (max-width: 1024px) {
    max-width: 0vw;
  }
  
  &.active {
    transition: .2s cubic-bezier(.18,.89,.32,1);
  }
}

.filler {
  width: fit-content;
  height: 100%;
  background-color: red;
  @apply px-5;
  
  @media (max-width: 1024px) {
    max-width: 0vw;
    @apply px-0;
  }
  
  .fillerPadding {
    width: 1.3rem;
  }
}

.aside {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  @apply border-r border-solid border-gray-200 bg-white z-20;
  
  @media screen and (min-width: 1024px) {
    max-width: 340px;
  }
}

.backdrop {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 10;
  transition: all 0.1s ease-in-out;
}

.wrapper:not(.active) .backdrop {
  opacity: 0;
  pointer-events: none;
  transition-delay: .2s;
}

.logoWrapper {  
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  
  @media screen and (min-width: 1024px) {
    padding: 0rem;
    min-height: 150px;
    
    .triggerClose {
      display: none;
    }
  }
  
  &.moveLogo .logoBeta {
    transition: .2s ease-in-out;
    left: 0px;
  }
  
  .logoBeta {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    transition: .2s ease-in-out;
    
    @media screen and (min-width: 1024px) {
      top: 0;
      left: 0;
    }
    
    // img.emblemVariant {
    //   position: absolute;
      
    //   top: 1rem;
    //   left: 0;
    //   right: 0;
      
    //   width: 100px;
    //   max-width: unset;
      
    //   transition: .3s ease-in-out;
    //   box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      
      
    //   @media screen and (min-width: 1024px) {
    //     translate: -20px 16px;
    //     rotate: 13deg;
    //   }
    // }
  }
}

.emblem {
  display: none;
  position: absolute;
  top: 1rem;
  left: 0rem;
  right: 0;
  width: 100px;
  max-width: unset;
  transition: .3s ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  
  @media screen and (min-width: 1024px) {
    display: block;
    translate: -20px 16px;
    rotate: 13deg;
  }
}
// .wrapper.active .logoBeta img.emblemVariant {
//   rotate: 0deg;
//   translate: 16px 0px;
//   box-shadow: none;
//   transition: 
//     all .3s ease-in-out,
//     translate .2s ease-in-out,
//   ;
// }

// .wrapper:not(.active):hover .logoBeta img.emblemVariant {
//   rotate: 10deg;
// }

.wrapper:not(.active) .logo {
  opacity: 0;
  max-width: 0;
  transition: .2s ease-in-out .1s;
}

.aside {
  cursor: pointer;
  
  @media (max-width: 1024px) {
    transform: translateX(-100%);
  }
}

.active .aside {
  width: 100%;
  transform: translateX(0);
}

.wrapper:not(.active) .menu {
  pointer-events: none;
}

.triggerWrapper { 
  position: absolute;
  top: 1rem;
  right: 1rem;
  transition: 0.2s ease-in-out;
  z-index: 10;
}

.trigger{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @apply rounded-full p-4 bg-white aspect-square;
  z-index: 10;
  
  @media screen and (min-width: 1024px) {
    display: none;    
  }
}