.footer {
  position: relative;
  @apply text-center text-gray-600 py-3;
  
  .content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    text-align: left;
    
    @apply flex-col lg:flex-row px-4;
    
    .start {
      display: flex;
      
      @apply flex-col lg:flex-row lg:items-center py-2;
      
      .separator {
        @apply hidden lg:inline-block mx-2;
      }
    }
  }
}

.credit {
  @apply border-t border-gray-200 lg:border-0 pt-3 lg:pt-0 mt-3 lg:mt-0 w-full lg:w-auto;
}